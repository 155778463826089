import { useNavigate } from 'react-router-dom'
import { routerData } from '../router/router';
import { useHistoryStore } from '../store/useHistoryStore';

/**
 * 라우터 관련 커스텀 훅
 * @author 최하영
 * @since 2023-11-27
 * @returns
 */
export const useRouter = () => {
	const { currentPath } = useHistoryStore();
	const router = useNavigate()
    const currentParentPath = currentPath?.split('/')[1]
	const accessibleRolesInCurrentPath = routerData.find( router => router.path === currentPath )?.accessibleRoles;
	const currentRouterKey = routerData.find( router => router.path === currentPath )?.key;
	const hasDetailPath = !! routerData.find( router => router.key === `${currentRouterKey}-detail`);
	

	return {
		routeTo: (path) => router(path),
		currentParentPath,
		accessibleRolesInCurrentPath,
		hasDetailPath
	}
}