import { create } from 'zustand'

/**
 * sidebar drawer의 상태를 관리합니다.
 *
 * @author 최하영
 * @since 2024-01-09
 */
export const useDrawerStore = create((set) => {
  return {
    openDrawer: false,
    setOpenDrawer: (boolean) =>
        set(() => ({
            openDrawer: boolean
        })),
  };
});