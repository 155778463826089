const storagePrefix = process.env.REACT_APP_STORAGE_PREFIX

export const saveUserToLocalStorage = (user) => {
    localStorage.setItem(`${storagePrefix}user`, user);
}
export const getUserFromLocalStorage = () => {
    return localStorage.getItem(`${storagePrefix}user`);
}
export const deleteUserFromLocalStorage = () => {
    localStorage.removeItem(`${storagePrefix}user`);
}
export const saveReportTitleToSessionStorage = (title) => {
    sessionStorage.setItem(`${storagePrefix}report`, title)
} 
export const getReportTitleFromSessionStorage = () => {
    return sessionStorage.getItem(`${storagePrefix}report`)
} 
export const deleteReportTitleFromSessionStorage = () => {
    sessionStorage.removeItem(`${storagePrefix}report`)
}