/**
 * 모든 중첩 자식에서 accessibleRoles 속성을 제거한다.
 *
 * @author 최하영
 * @since 2023-11-24
 * @param {Array} children 중첩 자식
 * @param {Array} accessibleRoles 접근가능한 역할 리스트
 * @param {Object} rest 위 두 속성을 제외한 나머지 속성들
 * @returns 모든 중첩 자식에서 accessibleRoles 속성을 제거한 객체
 */
// eslint-disable-next-line no-unused-vars
export const removeAccessibleRoles = ({ children, accessibleRoles, ...rest }) => ({
    ...rest,
    children: children && children.map(child => removeAccessibleRoles(child)),
});

/**
 * 객체 배열에서 key값이 주어진 targetKey값과 일치하는 객체를 찾는다.
 *
 * @author 최하영
 * @since 2023-11-24
 * @param {Object} objects 객체 배열
 * @param {String} targetKey 검색할 key값
 * @returns 검색된 객체
 */
export const findKeyInNestedObjects = (objects, targetKey) => {
    for (const obj of objects) {
      const targetObj = findKeyInObject(obj, targetKey);
      if (targetObj) {
        return targetObj;
      }
    }
  
    return null;
};

/**
 * 객체의 모든 중첩 자식에서 key값이 주어진 targetKey값과 일치하는 객체를 찾는다.
 *
 * @author 최하영
 * @since 2023-11-24
 * @param {Object} object 객체
 * @param {String} targetKey 검색할 key값
 * @returns 검색된 객체
 */
export const findKeyInObject = (object, targetKey) => {
    if (object.key === targetKey) {
      return object;
    }
  
    if (object.children) {
      const targetObj = findKeyInNestedObjects(object.children, targetKey);
      if (targetObj) {
        return targetObj;
      }
    }
  
    return null;
};