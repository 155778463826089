import { create } from 'zustand'

/**
 * detail drawer 상태 관리
 *
 * @author 최하영
 * @since 2024-03-08
 * @return detail drawer 상태 관리
 */
export const useDetailDrawerStore = create((set) => {
    return {
        detail: null,
        setDetail: (data) =>
            set(() => ({
                detail: data
            })),
        resetDetail: () => 
            set(() => ({
                detail: null
            })),
    };
});