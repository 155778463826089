/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spin, theme } from "antd";
import { themeConfig } from "../utils/themeConfig";

const { getDesignToken } = theme;
const globalToken = getDesignToken(themeConfig);

/**
 * 컨텐트박스에 사용되는 스피너
 * 
 * @author 최하영
 * @since 2023-12-04
 * @param { String } height 스피너 컨테이너 높이
 * @returns
 */
export default function ContentSpinner ({ height }) {
    return (
        <Spin size="large" css={ spinnerContainer(height) } />
    )
}

const spinnerContainer = (height) => css`
    height: ${height ?? `calc(100vh - ${globalToken.layoutSize.header})`} ;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`