import { formatFilterDateToString } from "../../utils/formatter";
import axiosInstance from "../axios";

export const getExceptionHistory = async ({ signal, pageInfo, sortInfo, searchInfo }) => {
    const { offset, limit } = pageInfo;
    let url = `exception-history-manage/exception-histories?offset=${offset}&limit=${limit}`;

    if (sortInfo && sortInfo.colId && sortInfo.sort) {
        const { colId, sort } = sortInfo;
        url += `&orderColumn=${colId}&orderDirection=${sort}`;
    }

    if (searchInfo.length > 0) {
        searchInfo.forEach(search => {
            const { colId, operator, value } = search;
            const parsedValue = colId.includes('Time') ? formatFilterDateToString(value, operator) : value;
            url += `&${colId}=${parsedValue}`;
        })
    }

    const response = await axiosInstance.get(url)

    if(response.result !== 'success') {
        console.log('not success', response)
        throw new Error(response)
    } 

    return response
}

export const sendErrorLog = async(formData) => {
    return await axiosInstance.post('exception-history-manage/exception-history', formData)
}