/** @jsxImportSource @emotion/react */

import { Link } from "react-router-dom";
import { Flex, theme } from "antd";
import { css } from "@emotion/react";
import AlertContent from "../components/AlertContent";
import { ExitIcon, HomeIcon } from "../components/icons";
import Button from "../components/buttons/Button";
import { themeConfig } from "../utils/themeConfig";
const { getDesignToken } = theme;
const globalToken = getDesignToken(themeConfig);

export default function NotFound() {
    return (
        <Flex vertical justify="center" align="center" style={{ height: 'calc(100vh - 214px)'}}>
            <Flex vertical css={ containerStyle }>
                <AlertContent title="요청하신 페이지를 찾을 수 없습니다.">
                    <div>방문하시려는 페이지의 주소가 잘못 입력되었거나,</div>
                    <div>페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.</div>
                    <div>입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.</div>
                </AlertContent>
                <Flex gap="14px" justify="end" style={{ marginTop: '30px' }}>
                    <Button onClick={() => {window.history.back()}} icon={<ExitIcon />}>
                        뒤로이동
                    </Button>
                    <Button type="primary" icon={<HomeIcon />}>
                        <Link to="/">홈으로이동</Link>
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}

export const containerStyle = css`
    padding: 25px 40px 30px;
    border-radius: 6px;
    border: .8px solid ${globalToken.motion_gray_300};
`