import './App.css';
import 'antd/dist/reset.css'
import { RouterProvider } from 'react-router-dom';

import { QueryClientProvider, QueryClient } from 'react-query';
import { Irouter } from './router/router';
import { ConfigProvider } from 'antd';
import { themeConfig } from './utils/themeConfig';

function App() {
	const queryClient = new QueryClient();
	
	return (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider theme={themeConfig}>
				<RouterProvider router={Irouter}>
					<div className="App" />
				</RouterProvider>
			</ConfigProvider>
		</QueryClientProvider>
	);
}

export default App;
