import { WarningIcon } from "./icons";

/**
 * alert 컴포넌트
 * 
 * @author 최하영
 * @since 2024-01-26
 * @param { String } title alert 타이틀
 * @param { Element } children 하위 요소
 * @returns alert 컴포넌트
 */
export default function AlertContent ({ title, children }) {
    return (
		<div style={{ textAlign: 'center'}}>
			<WarningIcon />
			<h1 style={{ margin: '35px 0 26px', fontSize: '20px' }}>{ title }</h1>
			<div style={{ fontSize: '16px', lineHeight: '30px' }}>
				{ children }
			</div>
		</div>
    )
}