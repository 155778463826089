/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { Flex } from "antd";
import { containerStyle } from "./NotFound";
import { ExitIcon, HomeIcon } from "../components/icons";
import Button from "../components/buttons/Button";
import AlertContent from "../components/AlertContent";
import { useResponsive } from "../hooks/useResponsive";

// eslint-disable-next-line no-unused-vars
export default function ErrorPage({error, resetErrorBoundary}) {
    const { windowSize } = useResponsive();
    const width = windowSize.width
    
    return (
        <Flex vertical justify="center" align="center" style={{ height: 'calc(100vh - 214px)', width: '100%' }}>
            <Flex vertical css={ containerStyle } style={{ width: width > 920 ? '536px' : '90%' }}>
                <AlertContent title="에러가 발생했습니다.">
                    <span>문제가 지속될 경우 관리자에게 문의 바랍니다.</span>
                </AlertContent>
                <Flex gap="14px" justify="end" style={{ marginTop: '30px' }}>
                    <Button onClick={() => {resetErrorBoundary()}} icon={<ExitIcon />}>
                        새로고침
                    </Button>
                    <Button type="primary" icon={<HomeIcon />}>
                        <Link to="/">홈으로이동</Link>
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}