/** @jsxImportSource @emotion/react */
import { useRouter } from "../hooks/useRouter"
import { useAuth } from "../hooks/useAuth";
import { getSelectedMenuKey, getSidebarContent } from "../router/router";
import { Drawer, Flex, Layout, Menu, theme } from 'antd';
import { findKeyInNestedObjects, removeAccessibleRoles } from "../utils/controlMenuList";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDrawerStore } from "../store/useDrawerStore";
import { useResponsive } from "../hooks/useResponsive";
import { css } from "@emotion/react";
import { themeConfig } from "../utils/themeConfig";
import Button from "../components/buttons/Button";
import { ExitIcon } from "../components/icons";
const { getDesignToken } = theme;
const globalToken = getDesignToken(themeConfig);
/**
 * 사이드바 컴포넌트
 *
 * @author 최하영
 * @since 2023-11-22
 * @returns 사이드바 컴포넌트
 */
export default function Sidebar () {
    const { loggedInUserInfo : userInfo, subjectAlias, isLoggedIn, handleLogOut } = useAuth();
	const [ visibleMenus, setVisibleMenus ] = useState();
	const [ openKeys, setOpenKeys ] = useState();
    const { Sider } = Layout;
    const { routeTo } = useRouter();
	const location = useLocation();
	const { collapsed } = useResponsive();
	const { openDrawer, setOpenDrawer } = useDrawerStore();
	const sidebarContent = getSidebarContent()
	// default selected key 설정
	const selectedKey = getSelectedMenuKey(location.pathname)?.key;
	const openedKeys = selectedKey?.includes('system') ? ['system'] : selectedKey?.includes('motioncore') ? ['motioncore'] : [];
	
	useEffect(() => {
		if (userInfo?.userType) {
			// 권한별 메뉴 설정
			const menus = sidebarContent.filter( element => {
				// 하위메뉴 권한별 필터링
				if(element.children?.length > 0) {
					element.children =  element.children.filter(child => child.accessibleRoles?.includes(userInfo?.userType))
				}
				if(element.key === 'subject') {
					element.label = `${subjectAlias} 관리`
				}
				if(element.key === 'measure') {
					element.label = `${subjectAlias} 측정 이력`
				}

				const isAccessiblePage = element?.accessibleRoles?.includes(userInfo.userType);
				return isAccessiblePage
			}).map( element => {
				return removeAccessibleRoles(element)
			})
			setVisibleMenus(menus)
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subjectAlias, userInfo?.userType])

    const handleMenuClick = (key) => {
        const target = findKeyInNestedObjects(sidebarContent, key);
        routeTo(target?.path)
		collapsed && setOpenDrawer(false)
    }

	const handleChangePassword = () => {
		routeTo('/update-password')
		setOpenDrawer(false)
	}
	const handleLogOutClick = () => {
		handleLogOut()
		setOpenDrawer(false)
	}
	const handleOpenChange = (keys) => {
		setOpenKeys([keys[keys.length - 1]])
	}
	const onClose = () => {
		setOpenDrawer(false)
	}

    return (
		<>
			{
				collapsed ?
					<Drawer
						placement="right"
						closable={true}
						onClose={ onClose }
						open={ openDrawer }
						key="right"
						css={ drawerStyle }
					>
						<Menu 
							mode="inline" 
							defaultOpenKeys={ openedKeys }
							defaultSelectedKeys={ selectedKey }
							items={ visibleMenus }  
							onClick={ (e) => handleMenuClick(e.key) }
							onOpenChange={ handleOpenChange }
							openKeys={ openKeys }
							css={menuStyle}
						/>
						{
							isLoggedIn &&
							<Flex gap="14px" justify="end" css={ buttonGroupStyle }>
								<Button onClick={handleChangePassword}>비밀번호 변경하기</Button>
								<Button onClick={handleLogOutClick} type="primary" icon={<ExitIcon />}>로그아웃</Button>
							</Flex>
						}
					</Drawer>
				:
					<Sider width={280} css={sidebarStyle}>
						<Menu
							css={menuStyle}
							mode="inline" 
							defaultOpenKeys={ openedKeys }
							defaultSelectedKeys={ selectedKey }
							items={ visibleMenus }  
							onClick={ (e) => handleMenuClick(e.key) }
							onOpenChange={ handleOpenChange }
							openKeys={ openKeys }
							selectedKeys= { selectedKey }
						/>
					</Sider>
			}
		</>
    )
}

const menuStyle = css`
	margin-top: 30px;
	border-inline-end: none !important;
	.ant-menu {
		border-inline-end: none;
	}
	.ant-menu-submenu-title,
	.ant-menu-item {
		padding-left: 48px !important;
		font-family: Pretendard-Medium;
	}
	.ant-menu-submenu-selected .ant-menu-submenu-title {
		font-weight: 600;
	}
	.ant-menu-item-selected:not(.ant-menu-item-only-child) {
		font-weight: 600;
	}
	.ant-menu-item-selected.ant-menu-item-only-child {
		color: ${globalToken.colorPrimary} !important;
	}
	.ant-menu-item-only-child {
		height: 50px;
		padding-left: 78px !important;
		color: #474747;
	}
`
const sidebarStyle = css`
	 border-right: 1.6px solid ${globalToken.motion_gray_300};
	 height: calc(100vh - 70px);
	 overflow-y: auto;
`
const drawerStyle = css`
	.ant-drawer-body {
		padding: 0;
	}
`
const buttonGroupStyle = css`
	width: 378px;
	background-color: #fff;
	padding: 14px;
	position: fixed;
	right: 0;
	bottom: 0;
`