const MOTION_BLACK = '#231815'
const MOTION_GRAY_100 = '#F5F5F5'
const MOTION_GRAY_200 = '#EBEBEB'
const MOTION_GRAY_300 = '#E0E0E0'
const MOTION_GRAY_400 = '#D6D6D6'
const MOTION_GRAY_500 = '#CCCCCC'
const MOTION_GRAY_600 = '#A3A3A3'
const MOTION_GRAY_700 = '#7A7A7A'
const MOTION_GRAY_800 = '#525252'
const MOTION_GRAY_900 = '#292929'
const MOTION_RED_100 = '#FCE9EB'
const MOTION_RED_200 = '#F9D2D7'
const MOTION_RED_300 = '#F6BCC2'
const MOTION_RED_400 = '#E74C5D'
const MOTION_RED_500 = '#E11F35'
const MOTION_RED_600 = '#B4192A'
const MOTION_GREEN_100 = '#E1F9D2'
const MOTION_GREEN_500 = '#69E11F'
const MOTION_BLUE_100 = '#E7F0FF'
const MOTION_BLUE_500 = '#1F6DE1'

export const themeConfig = {
    token: {
        colorPrimary: MOTION_RED_500,
        colorText: MOTION_BLACK,
        colorPrimaryActive: MOTION_RED_600,
        colorPrimaryHover: MOTION_RED_400,
        layoutSize: {
            header: '70px',
            sidebar: '280px',
            paddingY: '100px',
            paddingX: '90px'
        },
        fontFamily: 'Pretendard-Regular',
        fontSize: 16,
        borderRadius: 5,
        marginXS: 10,
        colorBorder: MOTION_GRAY_300,
        colorTextDisabled: MOTION_GRAY_500,
        lineWidth: .8,
        colorLink: MOTION_BLACK,
        colorLinkActive: '#AB2434',
        colorLinkHover: MOTION_RED_500,
        motion_black: MOTION_BLACK,
        motion_gray_100: MOTION_GRAY_100,
        motion_gray_200: MOTION_GRAY_200,
        motion_gray_300: MOTION_GRAY_300,
        motion_gray_400: MOTION_GRAY_400,
        motion_gray_500: MOTION_GRAY_500,
        motion_gray_600: MOTION_GRAY_600,
        motion_gray_700: MOTION_GRAY_700,
        motion_gray_800: MOTION_GRAY_800,
        motion_gray_900: MOTION_GRAY_900,
        motion_red_100: MOTION_RED_100,
        motion_red_200: MOTION_RED_200,
        motion_red_300: MOTION_RED_300,
        motion_red_400: MOTION_RED_400,
        motion_red_500: MOTION_RED_500,
        motion_red_600: MOTION_RED_600,
        motion_green_100: MOTION_GREEN_100,
        motion_green_500: MOTION_GREEN_500,
        motion_blue_100: MOTION_BLUE_100,
        motion_blue_500: MOTION_BLUE_500
    },
    components: {
        Layout: {
            headerBg: '#181919',
            headerHeight: 70,
            headerPadding: '0 40px',
            siderBg: '#fff',
            bodyBg: '#fff',
            
        },
        Menu: {
            itemHeight: 60,
            itemMarginBlock: 0,
            itemMarginInline: 0,
            itemPaddingInline: 0,
            itemSelectedBg: MOTION_RED_100,
            itemColor: MOTION_BLACK,
            itemHoverBg: MOTION_GRAY_200,
            subMenuItemBg: MOTION_GRAY_100,
            subMenuItemBorderRadius: 10,
            groupTitleFontSize: 18,
            iconMarginInlineEnd: 10
        },
        Breadcrumb: {
            iconFontSize: 16,
            linkColor: MOTION_GRAY_700,
            separatorColor: '#5B5B5B',
            separatorMargin: 10
        },
        Button: {
            paddingInline: 16,
            paddingInlineLG: 16,
            contentFontSize: 16,
            defaultGhostColor: MOTION_GRAY_500,
        },
        Pagination: {
            itemSize: 36
        },
        Input: {
            inputFontSize: 16,
            inputFontSizeLG: 16,
        },
        Radio: {
            buttonPaddingInline: 20
        },
        Alert: {
            colorErrorBg: MOTION_RED_100,
            colorErrorBorder: MOTION_GRAY_300,
        },
    }
};