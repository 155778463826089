import { create } from 'zustand'

/**
 * 테이블 상태 관리
 *
 * @author 최하영
 * @since 2023-11-29
 * @return 테이블 관련 상태 반환
 */
export const useDatagridStore = create((set) => {
  return {
	pageInfo: { offset: 0,  limit: 20 },
	sortInfo: { colId: null, sort: null },
	searchInfo: [],
	currentPage: 1,
	indexColumn: {
		field: "no",
        headerName: "#",
		width: 70,
		minWidth: 70,
        valueGetter: (params) => {
			const state = useDatagridStore.getState().pageInfo
			return params.node ? params.node.rowIndex + state.offset + 1 : null
		},
		cellStyle: { textAlign: 'center' },
        sortable: false,
		pinned: "left"
	},
	pageSize: 20,
	defaultVisibleColumns: null,
	isAnyCellExpanded: undefined,
	columnInfo: null,
	selectedItem: null,
	birthDateOperator: 'is',
	setPageInfo: ( offset, limit ) => 
		set(() => ({
			pageInfo: { offset, limit }
		})),
	setSortInfo: ( colId, sort ) => 
		set(() => ({
			sortInfo: { colId, sort }
		})),
	addSearchInfo: ({ colId, operator, value }) =>
		set((state) => ({
			searchInfo: state.searchInfo.some(item => item?.colId === colId) 
				? state.searchInfo.map(item => item.colId === colId ? {...item, operator, value} : item) 
				: [...state.searchInfo, {colId, operator, value}]
		})),
	removeSearchInfo: ( colId ) => 
		set((state) => ({
			searchInfo: state.searchInfo.filter(item => item.colId !== colId)
		})),
	clearSearchInfo: () => 
		set(() => ({
			searchInfo: []
		})),
	setCurrentPage: ( page ) => 
		set(() => ({
			currentPage: page
		})),
	setPageSize: ( pageSize ) => 
		set(() => ({
			pageSize: pageSize
		})),
	resetPageOffset: () => 
		set((state) => ({
			pageInfo: { ...state.pageInfo, offset: 0 },
			currentPage: 1,
		})),
	setDefaultVisibleColumns: ( columns ) => 
		set(() => ({
			defaultVisibleColumns: columns
		})),
	setIsAnyCellExpanded: ( boolean ) =>
		set(() => ({
			isAnyCellExpanded: boolean
		})),
	setColumnInfo: ( data ) =>
		set(() => ({
			columnInfo: data
		})),
	setSelectedItem: ( value ) =>
		set(() => ({
			selectedItem: value
		})),
	setBirthDateOperator: ( value ) =>
		set(() => ({
			birthDateOperator: value
		})),
	clearDatagridStore: () =>
		set(() => ({
			pageInfo: { offset: 0,  limit: 20 },
			sortInfo: { colId: null, sort: null },
			birthDateOperator: 'is',
			searchInfo: [],
			currentPage: 1,
			pageSize: 20,
			isAnyCellExpanded: undefined,
			columnInfo: null,
			selectedItem: null
		})),
	};
});