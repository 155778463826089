import axios from "axios";
import { deleteUserFromLocalStorage } from "../store/storage";
import { Modal } from "antd";
import AlertContent from "../components/AlertContent";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(config => {
    // 운동 관리, qna api는 multipart/form-data
    if (config.url.includes('exercise') || config.url.includes('import-data') || (config.url.includes('qna') && config.method !== 'delete' && !config.url.includes('status'))) {
        config.headers['Content-Type'] = "multipart/form-data"
    } else {
        config.headers['Content-Type'] = 'application/json'
    }
    return config
})

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 204) {
            Modal.destroyAll()

            const content = (
                <AlertContent title="WARNING">
                    <div>데이터를 불러올 수 없습니다.</div>
                </AlertContent>
            )

            const config = {
                icon: <></>,
                content,
                onOk: () => {
                    window.history.back()
                },
            };
            Modal.warning(config)
            return Promise.resolve(undefined)
        }
        return response.data;
        
    },
    (error) => {
        Modal.destroyAll()
        const exceptionCode = error.response?.data?.exceptionCode;
        const message = error.response?.data?.msg || error.message;
        const status = error.response?.status || error.status;
        let config;
        switch (status) {
            case 401:
                // 로그인: 아이디, 비밀번호 잘못 입력
                // 비밀번호 변경: 기존 비밀번호 불일치
                // 필드 하단에 에러메시지 처리
                if(exceptionCode === 'UNKNOWN_ACCOUNT' || exceptionCode === 'INVALID_PASSWORD') {
                    break;
                }
                
                // error.config.url === "account/logout" 인 경우, 유저 정보 제거 및 로그인 화면 이동
                // 그 외의 경우, refresh 토큰 재발급 필요하면 발급 시도
                // 성공 시, error.config 요청 다시 보내고 유저정보 업데이트
                // 실패 시, 유저정보 제거 및 로그인 화면 이동
                if (error.config.url === "account/logout") {
                    deleteUserFromLocalStorage();
                    window.location.href = '/login';
                    break;
                } else {
                    const content = (
                        <AlertContent title={`${status || "서버"} 에러가 발생했습니다.`}>
                            <div>로그인 정보가 존재하지 않습니다.</div>
                            <div>다시 로그인해주세요.</div>
                        </AlertContent>
                    )
    
                    const config = {
                        icon: <></>,
                        content,
                        onOk: () => {
                            deleteUserFromLocalStorage();
                            window.location.href = '/login';
                        }
                    };
                    Modal.warning(config)
                    break;
                }
            // eslint-disable-next-line no-fallthrough
            case 403: 
                if(exceptionCode === 'ROLE_NOT_VALID') {
                    config = {
                        icon: <></>,
                        content: <AlertContent title='WARNING'>{message}</AlertContent>,
                        style: { wordBreak: 'break-all', whiteSpace: 'pre-line'},
                        onOk: () => {
                            window.history.back();
                        }
                    };
                    Modal.warning(config)
                    break;
                }
            // eslint-disable-next-line no-fallthrough
            case 409: 
                if(exceptionCode === 'EXIST_USER' || exceptionCode === 'EXIST_ROLE_USER' || exceptionCode === 'ID_DUPLICATE_EXCEPTION') {
                    break;
                }
                if(message === '이미 등록된 사용자가 있습니다.') {
                    break;
                }
            // eslint-disable-next-line no-fallthrough
            case 422: 
                // 사용자 통합관리 아이디 중복 => 필드에 바로 표시
                if(exceptionCode === 'DUPLICATE_VALUE') {
                    break;
                }

                if(exceptionCode === 'EXCEL_FORM_ERROR') {
                    config = {
                        icon: <></>,
                        content: <AlertContent title='WARNING'>
                                {`등록할 수 없는 형식의 파일입니다.\n[샘플 엑셀 파일 다운로드] 버튼을 클릭하여 다운로드 받은 파일의 양식에 맞게 업로드 해주세요.`}
                            </AlertContent>,
                        style: { wordBreak: 'break-all', whiteSpace: 'pre-line'},
                        centered: true,
                    };
                } else {
                    config = {
                        icon: <></>,
                        content: <AlertContent title='WARNING'>{message}</AlertContent>,
                        style: { wordBreak: 'break-all', whiteSpace: 'pre-line'}
                    };
                }
                
                Modal.warning(config)
                break;
            
            default:
                // eslint-disable-next-line no-case-declarations
                const content = (
                    <AlertContent title={`${status || "서버"} 에러가 발생했습니다.`}>
                        <div>{ message }</div>
                        <div>관리자에게 문의바랍니다.</div>
                    </AlertContent>
                )

                Modal.error({icon:<></>, content})
                break;
        }


        return Promise.reject(error);
    }
)

export default axiosInstance;