/** @jsxImportSource @emotion/react */

import { Header as AntdHeader } from "antd/es/layout/layout";
import { css } from '@emotion/react';
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Dropdown } from "antd";
import { headerContent } from "../router/router";
import { useRouter } from "../hooks/useRouter";
import { useDrawerStore } from "../store/useDrawerStore";
import { useResponsive } from "../hooks/useResponsive";
import { HamburgerIcon, MotionCoreHeaderIcon } from "../components/icons";
import Button from "../components/buttons/Button";

/**
 * 헤더 컴포넌트
 *
 * @author 최하영
 * @since 2024-01-12
 * @returns 헤더 컴포넌트
 */
export default function Header () {
    const { isLoggedIn, handleLogOut, loggedInUserInfo } = useAuth();
    const { routeTo } = useRouter();
    const location = useLocation();
    const { setOpenDrawer } = useDrawerStore()
    const { collapsed } = useResponsive()

    const handleMenuClick = (e) => {
        if (e.key === 'update-password') {
            routeTo('/update-password')
        }
        if (e.key === 'logout') {
            handleLogOut();
        }
    }

    const handleMenuToggle = () => {
        setOpenDrawer(true)
    }

    return (
        <AntdHeader css={headerStyle}>
            <Link to='/' style={{ display: 'flex', alignItems: 'center'}}>
                <MotionCoreHeaderIcon />
            </Link>
            {
                isLoggedIn && !collapsed &&
                    <Dropdown menu={{ items: headerContent, onClick: handleMenuClick }}>
                        <div css={userMenuStyle}>
                            { loggedInUserInfo?.userName }
                        </div>
                    </Dropdown>
            }
            {
                collapsed && location.pathname !== '/login' && 
                <Button onClick={ handleMenuToggle } icon={<HamburgerIcon />} style={{ backgroundColor: 'transparent', border: 'none' }} />
            }
        </AntdHeader>
    )
}

const headerStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
`

const userMenuStyle = css`
    color: #fff;
    cursor: pointer;
    font-size: 18px;
`