import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useRouter } from "./useRouter";
import { logout } from "../api/auth";
import { deleteUserFromLocalStorage, getUserFromLocalStorage } from "../store/storage";

/**
 * 인증 관련 커스텀 훅
 * @author 최하영
 * @since 2023-11-30
 * @returns
 */
export const useAuth = () => {
    const queryClient = useQueryClient();
    const { routeTo } = useRouter();

    const cacheUserInfo = queryClient.getQueryData(['user']);
    const localUserInfo = getUserFromLocalStorage();
    const loggedInUserInfo = cacheUserInfo || JSON.parse(localUserInfo);
    const isIS = loggedInUserInfo?.userType === 'IS';

    const handleLogOut = useCallback(() => {
        logout()
            .then(() => {
                queryClient.setQueryData(['user'], null);
                deleteUserFromLocalStorage();
                queryClient.clear();
        
                routeTo('/login')
            })
            .catch(error => {
                console.log('error', error)
            })

    }, [routeTo, queryClient])

    return { 
        isLoggedIn: !! cacheUserInfo || !! localUserInfo, 
        loggedInUserInfo, 
        subjectAlias: isIS && loggedInUserInfo?.centerClassCode === 'S' ? '학생' : isIS && loggedInUserInfo?.centerClassCode === 'F' ? '회원' : '수검자',
        handleLogOut,
        userType: loggedInUserInfo?.userType
    }
}