import { create } from 'zustand'

/**
 * Q&A 페이지의 상태관리
 *
 * @author 최하영
 * @since 2024-03-28
 */
export const useQnAStore = create((set) => {
  return {
    category: null,
    comment: null,
    setCategory: (data) =>
        set(() => ({
            category: data
        })),
    setComment: (data) =>
        set(() => ({
            comment: data
        })),
    resetQnAStore: () => 
        set(() => ({
            category: null,
            comment: null
        }))
  };
});