/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button as AntdButton } from "antd";

/**
 * 공통 사용되는 버튼 컴포넌트
 * 
 * @author 최하영
 * @since 2024-01-23
 * @returns 버튼 컴포넌트
 */
export default function Button (props) {
    return (
        <AntdButton css={ buttonStyle } {...props}>
            { props.children }
        </AntdButton>
    )
}

const buttonStyle = css`
    padding: 0 16px;
    font-size: 16px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    line-height: 19.2px;
`