import { create } from 'zustand'

/**
 * history 상태를 관리합니다.
 *
 * @author 최하영
 * @since 2024-01-04
 */
export const useHistoryStore = create((set) => {
  return {
    history: [],
    currentPath: null,
    setHistory: (path) =>
        set((state) => ({
            history: [...state.history, path]
        })),
    setCurrentPath: (path) => 
        set(() => ({
            currentPath: path
        })),
  };
});