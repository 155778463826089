import dayjs from "dayjs";

/**
 * date 값을 서버 요청 시 사용되는 형식의 string 값으로 변환
 *
 * @author 최하영
 * @since 2023-11-30
 * @param {Date} date 날짜값
 * @param {String} operator 'onOrBefore' || 'onOrAfter' || 'is' || 'between'
 * @returns
 */
export const formatFilterDateToString = ( date, operator ) => {
    if (Array.isArray(date)) {
        const formattedDates = date.map( date => dayjs(date).format('YYYY-MM-DD'));
        return formattedDates.join(',');
    } else {
        // eslint-disable-next-line no-constant-condition
        return operator === 'onOrBefore' ? `,${date}` : operator === 'onOrAfter' ? `${date},` : 'is' ? `${date},${date}` : date;
    }
}
/**
 * 사용자가 입력한 date 값을 YYYY-MM-DD 형식으로 대체한다.
 *
 * @author 최하영
 * @since 2024-03-04
 * @param {Date} value 사용자 입력값
 * @returns
 */
export const replaceBirthDateValue = (value) => {
    const numberValue = value.replace(/-/g, '');
    if(numberValue.length !== 8) return value
    
    const replacedValue = value.replace(/[^0-9]/g, '');
    const isComplete = /^\d{8}$/.test(replacedValue);

    return isComplete ? dayjs(replacedValue).format('YYYY-MM-DD') : replacedValue
}
/**
 * camel case를 constant case로 변환한다.
 *
 * @author 최하영
 * @since 2023-12-20
 * @param {String} value 변환할값
 * @returns
 */
export const convertCamelToConstantCase = (value) => {
    return value.replace(/([A-Z])/g, '_$1').toUpperCase();
}

/**
 * 서버의 idNumber값을 유효한 값으로 변경 (23-010101 => 010101)
 *
 * @author 최하영
 * @since 2023-12-21
 * @param {String} idNumber 변환할값
 * @returns
 */
export const convertIdNumber = (idNumber) => {
    return  idNumber ? idNumber.substring(3) : null;
}

/**
 * null, undefined, '', 'NA' 값을 '-'로 치환한다.
 *
 * @author 최하영
 * @since 2023-12-21
 * @param {String} data 변환할값
 * @returns
 */
export const falsyDataToDash = (data) => {
    return !data || data === 'NA' ? '-' : data
}

/**
 * 수검자 정보 필드에 들어가는 데이터를 화면에 표시될 데이터로 변환한다.
 *
 * @author 최하영
 * @since 2023-12-21
 * @param {String} data 변환할값
 * @returns
 */
export const convertFieldValue = (fieldId, value) => {
    if (!value || value === 'NA') return '-'

    switch (fieldId) {
        case 'height':
            return `${ value } cm`
        case 'weight':
            return `${ value } kg`
        case 'shoesSize':
            return `${ value } mm`
        case 'sex':
            return value === 'F' ? '여' : value === 'M' ? '남' : '기타'
        default:
            return value
    }
}
/**
 * idNumber(ex: '24-010101')를 파싱하여 학년, 반, 번호 값 반환
 * @param {String} idNumber 학년 반 번호 서버값
 * @returns 학년, 반, 번호 객체 반환
 */
export const parseIdNumber = (idNumber) => {
    if(idNumber) {
        const parsedIdNumber =  idNumber?.split("-")[1];
        if(parsedIdNumber) {
            const grade = parsedIdNumber[1];
            const classNum = parseInt(parsedIdNumber.substring(2, 4));
            const studentNum = parseInt(parsedIdNumber.substring(4, 6));
            return { grade, classNum, studentNum }
        } else {
            const grade = parseInt(idNumber.slice(0, 2), 10);
            const classNum = parseInt(idNumber.slice(2, 4), 10);
            const studentNum = parseInt(idNumber.slice(4, 6), 10);

            return { grade, classNum, studentNum }
        }
    }
    return { grade: 1, classNum: null, studentNum: null }
}
/**
 * idNumber(ex: '24-010101')를 파싱하여 'n학년 n반 n번'형식으로 변환
 * @param {String} idNumber 학년 반 번호 서버값
 * @returns 가공한 문자열 반환
 */
export const formatIdNumberToStudentId = (idNumber) => {
    const { grade, classNum, studentNum } = parseIdNumber(idNumber)
  
    return grade && classNum && studentNum ? `${grade}학년 ${classNum}반 ${studentNum}번` : '';
}
/**
 * num1, num2, num3 을 조합하여 idNumber를 생성한다 (ex: '010101')
 * @param {Number} num1 학년 값
 * @param {Number} num2 반 값
 * @param {Number} num2 번호 값
 * @returns idNumber 반환
 */
export const formatStudentIdtoIdNumber = (num1, num2, num3) => {
    const padZero = (num) => num.toString().padStart(2, '0');
    
    const formattedNum1 = padZero(num1);
    const formattedNum2 = padZero(num2);
    const formattedNum3 = padZero(num3);
    
    return `${formattedNum1}${formattedNum2}${formattedNum3}`;
}