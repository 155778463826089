import { Grid } from "antd";
import { useEffect, useState } from "react";

/**
 * 화면 사이즈 커스텀 훅
 * @author 최하영
 * @since 2024-01-12
 * @returns
 */
const { useBreakpoint } = Grid;
export const useResponsive = () => {
    const screens = useBreakpoint();
    const [ windowSize, setWindowSize ] = useState({ width: window.innerWidth, height: window.innerHeight })

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])
    

    return { 
        collapsed: !screens.md,
        exceedsMaxSize: screens.xxl,
        isMobile: !screens.sm,
        windowSize
    }
}