import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useAuth } from "../hooks/useAuth";
import { App, Layout, theme } from "antd";
import { useRouter } from "../hooks/useRouter";
import { Suspense, useEffect } from "react";
import Header from "./Header";
import ContentSpinner from "../components/ContentSpinner";
import ErrorPage from "../pages/ErrorPage";
import { ErrorBoundary } from "react-error-boundary";
import { sendErrorLog } from "../api/log/exceptionHistory"

const { useToken } = theme;

/**
 * 루트 레이아웃 컴포넌트
 * 페이지 이동 시 인증 상태를 체크한다. 
 * @author 최하영
 * @since 2023-11-22
 * @returns
 */
export default function RootLayout() {     
    const { routeTo } = useRouter();
    const location = useLocation();
    const { isLoggedIn, handleLogOut } = useAuth();
    const { token } = useToken();

    useEffect(() => {
        // 페이지 이동 시마다 로그인 상태 체크하고, 로그인되어 있지 않으면 로그인 페이지로 이동시킨다.
        if (!isLoggedIn) {
            routeTo('/login')
            return
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 스토리지에서 유저정보를 삭제할 경우 강제 로그아웃시킨다.
    window.onstorage = (e) => {
        const isTarget = e.key === `${process.env.REACT_APP_STORAGE_PREFIX}user`;
        const isChanged = isTarget && e.newValue !== e.oldValue;
        
        if (isChanged) {
            if(e.newValue === null) {
                handleLogOut();
            } else {
                routeTo('/')
            }
        }
    }

    const logError = (error) => {
        process.env.NODE_ENV !== "development" && fetchError(error)
    }
    
    return (
        <App message={{ maxCount: 1}}>
            <Layout>
                <Header />
                <Layout style={{ minHeight: `calc(100vh - ${token.layoutSize.header})` }}>
                    {
                        location.pathname !== '/login' && <Sidebar />
                    }
                    <ErrorBoundary 
                        resetKeys={location.pathname}
                        fallbackRender={ fallbackProps => <ErrorPage {...fallbackProps} />} 
                        onError={logError}
                    > 
                        <Suspense style={{ width: '100%'}} fallback={ <ContentSpinner />}>
                            <Outlet />
                        </Suspense>
                    </ErrorBoundary>
                </Layout>
            </Layout>
        </App>
    )
}

const fetchError = async(error) => {
    const errorData = {
        exceptionCode: "WEB_UNKNOWN_EXCEPTION",
        exceptionCauseLocation: "WEB",
        exceptionLevel: "FATAL",
        stackTrace: error.stack,
    }
    try {
        const res = await sendErrorLog(errorData);
        console.log('error boundary res:', res)
    } catch (error) {
        console.log("error boundary error", error)
    }
}