import { Link, createBrowserRouter } from "react-router-dom";
import RootLayout from "../layout/RootLayout";
import ErrorPage from "../pages/ErrorPage";
import Login from "../pages/Login";
import GeneralLayout from "../layout/GeneralLayout";
import React, { lazy } from "react";
import NotFound from "../pages/NotFound";
import { ChartIcon, CommentIcon, LogIcon, ManualIcon, MotionCoreMenuIcon, SettingIcon, SubjectIcon } from "../components/icons";
import { useAuth } from "../hooks/useAuth";

const Home = lazy(() => import("../pages/Home"))
const CommonCode = lazy(() => import("../pages/system/CommonCode"));
const CommonCodeCreate = lazy(() => import("../pages/system/CommonCode/Create"));
const CommonCodeUpdate = lazy(() => import("../pages/system/CommonCode/Update"));
const UserMgt = lazy(() => import("../pages/motioncore/UserMgt"));
const UserMgtCreate = lazy(() => import("../pages/motioncore/UserMgt/Create"));
const UserMgtDetail = lazy(() => import("../pages/motioncore/UserMgt/Detail"));
const Role = lazy(() => import("../pages/system/Role"));
const RoleCreate = lazy(() => import("../pages/system/Role/Create"));
const RoleUpdate = lazy(() => import("../pages/system/Role/Update"));
const Action = lazy(() => import("../pages/system/Action"));
const ActionCreate = lazy(() => import("../pages/system/Action/Create"));
const ActionUpdate = lazy(() => import("../pages/system/Action/Update"));
const AccessHistory = lazy(() => import("../pages/log/AccessHIstory"));
const OperationHistory = lazy(() => import("../pages/log/OperationHistory"));
const ExceptionHistory = lazy(() => import("../pages/log/ExceptionHistory"));
const ExceptionCode = lazy(() => import("../pages/system/ExceptionCode"));
const ExceptionCodeCreate = lazy(() => import("../pages/system/ExceptionCode/Create"));
const ExceptionCodeUpdate = lazy(() => import("../pages/system/ExceptionCode/Update"));
const RoleActionMap = lazy(() => import("../pages/system/RoleActionMap"));
const UpdatePassword = lazy(() => import("../pages/UpdatePassword"));
const Center = lazy(() => import("../pages/motioncore/Center"))
const CenterCreate = lazy(() => import("../pages/motioncore/Center/Create"))
const CenterUpdate = lazy(() => import("../pages/motioncore/Center/Update"))
const Product = lazy(() => import("../pages/motioncore/Product"))
const ProductCreate = lazy(() => import("../pages/motioncore/Product/Create"))
const ProductDetail = lazy(() => import("../pages/motioncore/Product/Detail"))
const Report = lazy(() => import("../pages/motioncore/Report"))
const ReportCreate = lazy(() => import("../pages/motioncore/Report/Create"))
const ReportUpdate = lazy(() => import("../pages/motioncore/Report/Update"))
const CenterReportMap = lazy(() => import("../pages/motioncore/CenterReportMap"))
const MeasureStatusHistory = lazy(() => import("../pages/log/MeasureStatusHistory"))
const Measure = lazy(() => import("../pages/Measure"))
const MeasureDetail = lazy(() => import("../pages/Measure/Detail"))
const MeasureReport = lazy(() => import("../pages/Measure/ReportDetail"))
const Exercise = lazy(() => import("../pages/motioncore/Exercise"))
const ExerciseCreate = lazy(() => import("../pages/motioncore/Exercise/Create"))
const ExerciseUpdate = lazy(() => import("../pages/motioncore/Exercise/Update"))
const Subject = lazy(() => import("../pages/Subject"))
const SubjectDetail = lazy(() => import("../pages/Subject/Detail"))
const QnA = lazy(() => import("../pages/QnA"))
const SubjectCreate = lazy(() => import("../pages/Subject/Create"))
const QnACreate = lazy(() => import("../pages/QnA/Create"))
const QnADetail = lazy(() => import("../pages/QnA/Detail"))
const QnAUpdate = lazy(() => import("../pages/QnA/Update"))
const Excel = lazy(() => import("../pages/Subject/Excel"))
const Manual = lazy(() => import("../pages/Manual"))

// 사이드바 & 라우터 동시 사용
export const routerData = [
    // 로그인 불필요 페이지
    {
		key: 'login',
		path: '/login',
		label: '로그인',
		element: <Login/>,
		isSidebarContent: false,
    },
    // 로그인 필요 페이지
    {
		key: 'home',
		index: true,
		path: '/',
		label: '홈',
		element: <Home />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV', 'CS', 'IS']
    },
	{
		key: 'system-common-code',
		path: '/common-code',
		label: '공통 기준 관리',
		element: <CommonCode />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'system-common-code-create',
		path: '/common-code/create',
		label: '공통 기준 등록',
		element: <CommonCodeCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'system-common-code-update',
		path: '/common-code/update/:code',
		label: '공통 기준 수정',
		element: <CommonCodeUpdate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'motioncore-user-mgt',
		path: '/user-mgt',
		label: '사용자 통합 관리',
		element: <UserMgt />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'motioncore-user-mgt-create',
		path: '/user-mgt/create',
		label: '사용자 등록',
		element: <UserMgtCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'motioncore-user-mgt-detail',
		path: '/user-mgt/detail/:userId',
		label: '사용자 통합 관리 상세보기',
		element: <UserMgtDetail />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'system-role',
		path: '/role',
		label: '역할 관리',
		element: <Role />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'system-role-create',
		path: '/role/create',
		label: '역할 등록',
		element: <RoleCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'system-role-update',
		path: '/role/update/:roleId',
		label: '역할 수정',
		element: <RoleUpdate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'system-action',
		path: '/action',
		label: '기능 관리',
		element: <Action />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'system-action-create',
		path: '/action/create',
		label: '기능 등록',
		element: <ActionCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'system-action-update',
		path: '/action/update/:query',
		label: '기능 수정',
		element: <ActionUpdate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'system-role-action',
		path: '/role-action-map',
		label: '역할 - 기능 매핑',
		element: <RoleActionMap />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'log-access',
		path: '/access-history',
		label: '접속 이력',
		element: <AccessHistory />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'log-SPVeration',
		path: '/operation-history',
		label: '작업 이력',
		element: <OperationHistory />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'system-exception-code',
		path: '/exception-code',
		label: '오류 코드 관리',
		element: <ExceptionCode />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'system-exception-code-create',
		path: '/exception-code/create',
		label: '오류 코드 등록',
		element: <ExceptionCodeCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'system-exception-code-update',
		path: '/exception-code/update/:exceptionCode',
		label: '오류 코드 수정',
		element: <ExceptionCodeUpdate />,
		isSidebarContent: false,
		accessibleRoles: ['SA'],
	},
	{
		key: 'log-exception-history',
		path: '/exception-history',
		label: '오류 이력',
		element: <ExceptionHistory />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'motioncore-center',
		path: '/center',
		label: '센터 관리',
		element: <Center />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV', 'CS']
	},
	{
		key: 'motioncore-center-create',
		path: '/center/create',
		label: '센터 등록',
		element: <CenterCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA']
	},
	{
		key: 'motioncore-center-update',
		path: '/center/update/:centerId',
		label: '센터 수정',
		element: <CenterUpdate />,
		isSidebarContent: false,
		accessibleRoles: ['SA']
	},
	{
		key: 'motioncore-product',
		path: '/product',
		label: '제품 관리',
		element: <Product />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV']
	},
	{
		key: 'motioncore-product-create',
		path: '/product/create',
		label: '제품 등록',
		element: <ProductCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA']
	},
	{
		key: 'motioncore-product-detail',
		path: '/product/detail/:productId',
		label: '제품 상세보기',
		element: <ProductDetail />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'motioncore-report',
		path: '/report',
		label: '리포트 관리',
		element: <Report />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV']
	},
	{
		key: 'motioncore-report-create',
		path: '/report/create',
		label: '리포트 등록',
		element: <ReportCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA']
	},
	{
		key: 'motioncore-report-update',
		path: '/report/update/:reportId',
		label: '리포트 수정',
		element: <ReportUpdate />,
		isSidebarContent: false,
		accessibleRoles: ['SA']
	},
	{
		key: 'motioncore-center-report',
		path: '/center-report-map',
		label: '센터 - 리포트 매핑',
		element: <CenterReportMap />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV'],
	},
	{
		key: 'motioncore-exercise',
		path: '/exercise',
		label: '운동 관리',
		element: <Exercise />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV']
	},
	{
		key: 'motioncore-exercise-create',
		path: '/exercise/create',
		label: '운동 등록',
		element: <ExerciseCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA']
	},
	{
		key: 'motioncore-exercise-detail',
		path: '/exercise/update/:exerciseId',
		label: '운동 수정',
		element: <ExerciseUpdate />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV']
	},
	{
		key: 'log-measure-history',
		path: '/measure-status-history',
		label: '분석 상태 이력',
		element: <MeasureStatusHistory />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV', 'CS']
	},
    {
		key: 'subject',
		path: '/subject',
		label: `수검자 관리`,
		icon: <SubjectIcon />,
		element: <Subject />,
		isSidebarContent: true,
		accessibleRoles: ['IS', 'SPV', 'CS', 'SA'],
    },
    { 
		key: 'subject-detail',
		path: '/subject/detail/:id',
		label: `수검자 상세보기`,
		element: <SubjectDetail />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV', 'CS', 'IS'],
    },
	{
		key: 'subject-create',
		path: '/subject/create',
		label: '수검자 등록',
		element: <SubjectCreate />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'IS']
	},
	{
		key: 'subject-excel',
		path: '/subject/excel',
		label: '엑셀 파일로 등록',
		element: <Excel />,
		isSidebarContent: false,
		accessibleRoles: ['IS']
	},
	{
		key: 'measure',
		path: '/measure',
		label: `수검자 측정 이력`,
		icon: <ChartIcon />,
		element: <Measure />,
		isSidebarContent: true,
		accessibleRoles: ['SA', 'SPV', 'CS', 'IS']
	},
	{
		key: 'measure-detail',
		path: '/measure/detail/:id',
		label: '수검자 측정 이력 상세보기',
		element: <MeasureDetail />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV', 'CS', 'IS']
	},
	{
		key: 'motioncore-measure-report',
		path: '/measure/detail/:id/:report',
		label: '리포트 상세보기',
		element: <MeasureReport />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV', 'CS', 'IS']
	},
    {
		key: 'board',
		path: '/board',
		label: 'Q&A',
		icon: <CommentIcon style={{ fontSize: '24px'}} />,
		element: <QnA />,
		isSidebarContent: true,
		accessibleRoles: ['IS', 'SPV', 'CS', 'SA']
    },
	{
		key: 'board-create',
		path: '/board/create',
		label: '질문등록',
		element: <QnACreate />,
		isSidebarContent: false,
		accessibleRoles: ['IS']
    },
	{
		key: 'board-detail',
		path: '/board/detail/:boardId',
		label: '상세 페이지',
		element: <QnADetail />,
		isSidebarContent: false,
		accessibleRoles: ['IS', 'SPV', 'CS', 'SA']
    },
	{
		key: 'board-update',
		path: '/board/update/:boardId',
		label: '질문수정',
		element: <QnAUpdate />,
		isSidebarContent: false,
		accessibleRoles: ['IS']
    },
	{
		key: 'manual',
		path: '/manual',
		label: '매뉴얼 다운로드',
		icon: <ManualIcon />,
		element: <Manual />,
		isSidebarContent: true,
		accessibleRoles: ['IS', 'SPV', 'CS', 'SA']
    },
	{
		key: 'update-password',
		path: '/update-password',
		label: '비밀번호 변경',
		element: <UpdatePassword />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV', 'CS', 'IS']
	},
	{
		key: 'not-found',
		path: '*',
		label: '404',
		element: <NotFound />,
		isSidebarContent: false,
		accessibleRoles: ['SA', 'SPV', 'CS', 'IS']
	},
]

export const Irouter = createBrowserRouter([
    {
		path: '/',
		element: <RootLayout />,
		errorElement: <ErrorPage />,
		children: routerData.map(router => {
			// eslint-disable-next-line no-prototype-builtins
			const withAuth = router.hasOwnProperty('accessibleRoles')
			if (withAuth) {
				// 로그인 필요한 페이지 인증 컴포넌트로 감싸주기
				return {
					path: router.path,
					element: <GeneralLayout accessibleRoles={ router?.accessibleRoles }>
								{ router.element }
							</GeneralLayout>,
					children: router.children
				}
			} else {
				return {
					path: router.path,
					element: router.element
				}
			}
		})
    }
])

const systemMenu = routerData.filter(r => r.key.includes('system') && r.isSidebarContent);
const motioncoreMenu = routerData.filter(r => r.key.includes('motioncore') && r.isSidebarContent);
const logMenu = routerData.filter(r => r.key.includes('log')  && r.isSidebarContent);
const otherMenu = routerData.filter(r => !r.key.includes('system') && !r.key.includes('motioncore') && !r.key.includes('log') && r.isSidebarContent);

export const getSelectedMenuKey = (path) => {
	const pathSegments = path.split('/');
	const parsedPath = `/${pathSegments[1]}`;

	return routerData.find(router => router.path === parsedPath);
}

export const getBreadcrumbItems = (pathnames, subjectAlias) => {
	const items = []
	pathnames.forEach((menu, i) => {
		const searchPath = `${pathnames[i-1] ? `/${pathnames[i-1]}/` : '/'}${pathnames[i]}`;
		const routeItem = routerData.find(router => router.path === searchPath)

		if (routeItem?.key === 'subject') {
			routeItem.label = `${subjectAlias} 관리`
		}
		if (routeItem?.key === 'subject-create') {
			routeItem.label = `${subjectAlias} 등록`
		}
		if (routeItem?.key === 'measure') {
			routeItem.label = `${subjectAlias} 측정 이력`
		}

		if (routeItem) {
			i === pathnames.length - 1 ? items.push({ title: routeItem.label }) 
			: items.push({ title: <Link to={routeItem.path}>{routeItem.label}</Link> })
		}
		if (menu === 'update') {
			items.push({ title: '수정 페이지' })
		}
		if (pathnames[i-1] === 'detail') {
			i === pathnames.length - 1 ? items.push({ title: '상세 페이지' }) 
			: items.push({ title: <Link to='..' relative="path">상세 페이지</Link> })
		}
		if (menu.includes('reportNo')) {
			items.push({ title: '리포트'})
		}
	})
	return items
}

export const getSidebarContent = () => {
	const { loggedInUserInfo } = useAuth();
	if(!loggedInUserInfo) return null;
	const { centerClassCode, userType } = loggedInUserInfo
	const sidebarContent = [
		{
			key: 'system',
			icon: <SettingIcon />,
			label: '시스템 관리',
			children: systemMenu.map( menu => {
				return {
					key: menu.key,
					path: menu.path,
					label: menu.label,
					icon: menu.icon,
					accessibleRoles: menu.accessibleRoles,
				}
			}),
			accessibleRoles: ['SA', 'SPV'],
		},
		{
			key: 'log',
			icon: <LogIcon />,
			label: '시스템 로그',
			children: logMenu.map( menu => {
				return {
					key: menu.key,
					path: menu.path,
					label: menu.label,
					icon: menu.icon,
					accessibleRoles: menu.accessibleRoles,
				}
			}),
			accessibleRoles: ['SA', 'SPV', 'CS'],
		},
		{
			key: 'motioncore',
			icon: <MotionCoreMenuIcon />,
			label: '모션코어 관리',
			children: motioncoreMenu.map( menu => {
				return {
					key: menu.key,
					path: menu.path,
					label: menu.label,
					icon: menu.icon,
					accessibleRoles: menu.accessibleRoles,
				}
			}),
			accessibleRoles: ['SA', 'SPV', 'CS'],
		},
		...otherMenu.map( menu => {
			if( menu.key === 'manual' && (userType === 'IS' && centerClassCode !== 'S')) return {}
			return {
				key: menu.key,
				path: menu.path,
				label: menu.label,
				icon: menu.icon,
				accessibleRoles: menu.accessibleRoles,
			}
		})
	]
	return sidebarContent
}
export const headerContent = [
	{
		label: '비밀번호 변경',
		key: 'update-password',
	},
	{
		label: '로그아웃',
		key: 'logout',
	},
]