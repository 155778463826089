/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from "react"
import { useRouter } from "../hooks/useRouter"
import { useAuth } from "../hooks/useAuth"
import { Content } from "antd/es/layout/layout"
import { css } from "@emotion/react"
import { useDatagridStore } from "../store/useDatagridStore"
import { useLocation } from "react-router-dom"
import { useHistoryStore } from "../store/useHistoryStore"
import { getBreadcrumbItems } from "../router/router"
import { Breadcrumb } from "antd"
import { useResponsive } from "../hooks/useResponsive"
import { useDetailDrawerStore } from "../store/useDetailDrawerStore"
import { useQnAStore } from "../store/useQnAStore"

/**
 * 로그인이 필요한 페이지의 공통 레이아웃
 *
 * @author 최하영
 * @since 2023-11-22
 * @param {Element} children 하위 컴포넌트
 * @param {Array} accessibleRoles 접근가능한 role 리스트
 * @returns 로그인이 필요한 페이지의 공통 레이아웃
 */
export default function GeneralLayout ({children, accessibleRoles}) {
    const { routeTo } = useRouter()
    const { history, currentPath, setCurrentPath, setHistory } = useHistoryStore();
    const { loggedInUserInfo : userInfo, subjectAlias } = useAuth();
    const { clearDatagridStore } = useDatagridStore()
    const { detail, resetDetail } = useDetailDrawerStore();
    const { resetQnAStore } = useQnAStore();
    const [ render, setRender ] = useState(false);
    const location = useLocation();
    const [ breadcrumbItems, setBreadcrumbItems ] = useState([])
    const { exceedsMaxSize } = useResponsive();
    const layoutRef = useRef()

    useEffect(() => {
        setCurrentPath(location.pathname)
        setHistory(location.pathname)
        const pathnames =  location.pathname === '/' ? [''] : location.pathname.split('/');
        const items = getBreadcrumbItems(pathnames, subjectAlias);

        setBreadcrumbItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useEffect(() => {
        if(history && history.length > 1) {
            detail && resetDetail() // 페이지 이동 시 detail drawer 열려있을 경우 초기화

            if(layoutRef?.current) { // 페이지 이동 시 스크롤 최상단으로 이동 처리
                layoutRef.current.scrollTo(0, 0)
            }

            const prevParentPath = history[history.length -2]?.split('/')[1];
            const currentParentPath = currentPath.split('/')[1];
        
            if(prevParentPath !== currentParentPath) {
                clearDatagridStore()
                if(currentParentPath === 'board') resetQnAStore();    
            }    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history])

    useEffect(() => {
        // 권한 없는 페이지 접속 시 홈화면 이동
        if (!accessibleRoles?.includes(userInfo?.userType)) {
            routeTo('/')
            return
        }

        setRender(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children])
    
    return (
        <Content css={ contentStyle(exceedsMaxSize) } ref={layoutRef}>
            <div style={{ maxWidth: '1640px', margin: exceedsMaxSize ? '0 auto' : '0' }}>
                { children && currentPath !== '/' && <Breadcrumb items={ breadcrumbItems } separator=">" /> }
                { render && children }
            </div>
        </Content>
    )
}

export const contentStyle = xxl => css`
    padding: 40px 40px 80px 50px;
    margin: ${xxl ? '0 auto' : '0'};
    height: calc(100vh - 70px);
    overflow-y: auto;
`