import axiosInstance from "../api/axios";
import { sha512 } from "js-sha512";

export const login = (formData) => {
    return axiosInstance.post('account/login', {
        id: formData.id,
        authType: "idPassword",
        password: sha512(formData.password),
    });
};

export const getCurrentUserInfo = () => {
    return axiosInstance.get('user-manage/my')
}

export const logout = () => {
    return axiosInstance.get('account/logout')
}

export const updatePassword = (formData) => {
    return axiosInstance.patch('user/auth/my-password', {
        oldPassword: sha512(formData.currentPassword),
        newPassword: sha512(formData.newPassword),
    })
}