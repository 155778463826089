/** @jsxImportSource @emotion/react */
import { useMutation, useQueryClient } from "react-query";
import { login } from "../api/auth";
import { useEffect } from "react";
import { saveUserToLocalStorage } from "../store/storage";
import { useRouter } from "../hooks/useRouter";
import { useAuth } from "../hooks/useAuth";
import { Button, Flex, Form, Input, Typography, theme } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { MotionCoreLogo } from "../components/icons";
import { useResponsive } from "../hooks/useResponsive";
import { themeConfig } from "../utils/themeConfig";
const { getDesignToken } = theme;
const globalToken = getDesignToken(themeConfig);

/**
 * 로그인 페이지
 *
 * @author 최하영
 * @since 2023-11-22
 * @returns 로그인 컴포넌트
 */
export default function Login () {
    const queryClient = useQueryClient();
    const { routeTo } = useRouter();
    const { isLoggedIn } = useAuth();
    const { Text } = Typography;
    const { windowSize } = useResponsive();

    const { mutate, isLoading, error } = useMutation(login, {
        onSuccess: data => {
            if (data.result === "success") {
                queryClient.setQueryData(['user'], data)
                saveUserToLocalStorage(JSON.stringify(data))
            } else {
                console.log('not success', data)
            }
        },
        onError: error => {
            console.log('error', error)
        }
    })

    // 이미 로그인되어 있을 경우 홈화면 이동
    useEffect(() => {
        if (isLoggedIn) {
            return routeTo('/')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    function handleLogin(formData) {
        mutate(formData)
    }


    return (
        <Flex vertical justify="center" align="center" css={ loginBackgroundStyle }>
            <Form 
                name="login"
                onFinish={ handleLogin } 
                css={ formStyle(windowSize.width) }
            >
                <div  style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px'}}>
                    <MotionCoreLogo />
                </div>
                <Form.Item name="id" css={ loginInputStyle(30) }>
                    <Input 
                        prefix={<UserOutlined style={{ color: globalToken.colorPrimary }} />}
                        placeholder="아이디" 
                        size="large"
                    />
                </Form.Item>
                <Form.Item name="password" css={ loginInputStyle(20) }>
                    <Input.Password
                        prefix={<LockOutlined style={{ color: globalToken.colorPrimary }} />}
                        placeholder="비밀번호"
                        size="large"
                        autoComplete="off"
                    />
                </Form.Item>
                {
                    <div style={{ margin: '1em 0' }}>
                        {
                            error && error?.response?.data?.exceptionCode === "UNKNOWN_ACCOUNT" &&
                            <Text type="danger">{ error?.response?.data?.msg }</Text>
                        }
                    </div>
                }
                <Button type="primary" htmlType="submit" loading={ isLoading } css={ loginButtonStyle } block>
                    로그인
                </Button>
            </Form>
        </Flex>
    )
}

const loginBackgroundStyle = css`
    height: calc(100vh - 70px);
    margin: auto 0;
    background-image: url("/img/1920_background.png");
    background-color: #fff;
    background-size: cover;
`
const formStyle = (collapsed) => css`
    max-width: 588px;
    margin: 0;
    width: 100%;
    height: 588px;
    background-color: #F9F9F9CC;
    box-shadow: 2px 3px 12.1px -3px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    padding: ${collapsed < 588 ? '25% 10%' : '112px 101px 135px' };

    .ant-input-outlined:hover,
    .ant-input-outlined:focus-within,
    .ant-input-outlined:focus {
        border-color: ${globalToken.colorPrimary};
        box-shadow: none;
    }
`
const loginInputStyle = (marginBottom) => css`
    margin-bottom: ${marginBottom}px;
    border-color: ${globalToken.motion_gary_300};
    .ant-input-affix-wrapper-lg {
        height: 54px;
    }
`
const loginButtonStyle = css`
    height: 54px;
    font-size: 18px;
    border-radius: 10px;
`